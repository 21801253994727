import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
//import { Camera } from '@ionic-native/Camera/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';
//import {NgxPaginationModule} from 'ngx-pagination';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { Media } from '@awesome-cordova-plugins/media/ngx';
import { DatePipe } from '@angular/common';

// Import modules
import { Platform } from '@ionic/angular';
//firebase
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,HttpClientModule,/*NgxPaginationModule*/],
  providers: [ 
    Media, 
    Geolocation,
    BarcodeScanner,  
    Platform,
    PhotoViewer,
    DatePipe,
    OneSignal,
    //StatusBar,
   // SplashScreen,
    //StatusBar,
    //Camera,
    FirebaseX,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
