import { Component } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { AlertController, LoadingController, MenuController, ModalController, NavController } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  activeIndex: Number;
  i: Number;
  isApp = false;
  constructor(private firebase: FirebaseX, private oneSignal: OneSignal,public httpClient: HttpClient,   public modalCtrl: ModalController,
    public menuCtrl: MenuController,
    public alertController: AlertController,
    public navCtrl: NavController,
    public loadingCtrl:LoadingController,
    public cookieService: CookieService,
) {
    this.initializeApp();
  }

  async checkVersion(){
    const loading = await this.loadingCtrl.create({message : 'Loading ...'});
    loading.present();  
    const headers = new HttpHeaders()
    let postData = new FormData();
    postData.append('app' , 'livreur');
    postData.append('version' , localStorage.getItem('version'));
    return this.httpClient
    
    .post('https://leoexpress.tn/aapi8/checkversion.php',postData  , { headers: headers})
    .subscribe(data => {
      this.menuCtrl.enable(true);
      if(data['rep']=='true'){
  
      loading.dismiss();
      
  
      }else{
      
        loading.dismiss();
        this.navCtrl.navigateRoot('/login');
       
      }  
      }, error => {
        loading.dismiss();
        this.problogin();
     
    });
  }
  async problogin() {
    const alert = await this.alertController.create({
      cssClass: 'alert-style-livreur',
      header: 'Problème de connexion',
      subHeader: '',
      message: 'Problème de connexion',
      buttons: ['Réessayer']
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    
  }
  initializeApp() {
    localStorage.setItem('version', '5.0.1');
    this.checkVersion();

    this.firebase.subscribe('sfax');

    //firebase init
    this.firebase.getToken()
      .then(token => 
        console.log(`The token is ${token}`)
        ) // save the token server-side and use it to push notifications to this device
      .catch(error => console.error('Error getting token', error));

    this.firebase.onMessageReceived()
      .subscribe(data => alert(`User opened a notification ${data.get("test")}`));
        
    this.firebase.onTokenRefresh()
      .subscribe((token: string) => console.log(`Got a new token ${token}`));
  }





}
