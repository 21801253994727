import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },

 
  {
    path: 'add-ramassage',
    loadChildren: () => import('./add-ramassage/add-ramassage.module').then( m => m.AddRamassagePageModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then( m => m.MainPageModule)
  },
  {
    path: 'edit',
    loadChildren: () => import('./edit/edit.module').then( m => m.EditPageModule)
  },
  {
    path: 'remplissage',
    loadChildren: () => import('./remplissage/remplissage.module').then( m => m.RemplissagePageModule)
  },
  {
    path: 'livraisons',
    loadChildren: () => import('./livraisons/livraisons.module').then( m => m.LivraisonsPageModule)
  },
  {
    path: 'affectations',
    loadChildren: () => import('./affectations/affectations.module').then( m => m.AffectationsPageModule)
  },
  {
    path: 'livraisonvalidation',
    loadChildren: () => import('./livraisonvalidation/livraisonvalidation.module').then( m => m.LivraisonvalidationPageModule)
  },
  {
    path: 'gpsclients',
    loadChildren: () => import('./gpsclients/gpsclients.module').then( m => m.GpsclientsPageModule)
  },
  {
    path: 'rechercheclient',
    loadChildren: () => import('./rechercheclient/rechercheclient.module').then( m => m.RechercheclientPageModule)
  },
  {
    path: 'livraisonsnonaffectes',
    loadChildren: () => import('./livraisonsnonaffectes/livraisonsnonaffectes.module').then( m => m.LivraisonsnonaffectesPageModule)
  },
  {
    path: 'livraisonstri',
    loadChildren: () => import('./livraisonstri/livraisonstri.module').then( m => m.LivraisonstriPageModule)
  },
  {
    path: 'connecterentantque',
    loadChildren: () => import('./connecterentantque/connecterentantque.module').then( m => m.ConnecterentantquePageModule)
  },
  {
    path: 'changelivreuraffecte',
    loadChildren: () => import('./changelivreuraffecte/changelivreuraffecte.module').then( m => m.ChangeLivreurAffectePageModule)
  },
  {
    path: 'rapportlivreur',
    loadChildren: () => import('./rapportlivreur/rapportlivreur.module').then( m => m.RapportlivreurPageModule)
  },
  {
    path: 'add-demande',
    loadChildren: () => import('./add-demande/add-demande.module').then( m => m.AddDemandePageModule)
  },
  {
    path: 'demandes',
    loadChildren: () => import('./demandes/demandes.module').then( m => m.DemandesPageModule)
  },
  {
    path: 'demandes-affectes',
    loadChildren: () => import('./demandes-affectes/demandes-affectes.module').then( m => m.DemandesAffectesPageModule)
  },
  {
    path: 'livraisonphotos',
    loadChildren: () => import('./livraisonphotos/livraisonphotos.module').then( m => m.LivraisonphotosPageModule)
  },
  {
    path: 'livraisonphotoscr',
    loadChildren: () => import('./livraisonphotoscr/livraisonphotoscr.module').then( m => m.LivraisonphotoscrPageModule)
  },
  {
    path: 'livraisonslivres',
    loadChildren: () => import('./livraisonslivres/livraisonslivres.module').then( m => m.LivraisonslivresPageModule)
  },

  {
    path: 'verifier-demande',
    loadChildren: () => import('./verifier-demande/verifier-demande.module').then( m => m.VerifierDemandePageModule)
  },
  {
    path: 'add-ramassage-new',
    loadChildren: () => import('./add-ramassage-new/add-ramassage-new.module').then( m => m.AddRamassageNewPageModule)
  },
  {
    path: 'add-ramassage-new',
    loadChildren: () => import('./add-ramassage-new/add-ramassage-new.module').then( m => m.AddRamassageNewPageModule)
  },
  {
    path: 'credit',
    loadChildren: () => import('./credit/credit.module').then( m => m.CreditPageModule)
  },
  {
    path: 'yearly-credit',
    loadChildren: () => import('./yearly-credit/yearly-credit.module').then(m => m.YearlyCreditPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
