import { Component } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  activeIndex: Number;
  i: Number;
  isApp = false;
  constructor(private firebase: FirebaseX, private oneSignal: OneSignal,public httpClient: HttpClient) {
    this.initializeApp();
  }


  initializeApp() {

    this.firebase.subscribe('sfax');

    //firebase init
    this.firebase.getToken()
      .then(token => 
        console.log(`The token is ${token}`)
        ) // save the token server-side and use it to push notifications to this device
      .catch(error => console.error('Error getting token', error));

    this.firebase.onMessageReceived()
      .subscribe(data => alert(`User opened a notification ${data.get("test")}`));
        
    this.firebase.onTokenRefresh()
      .subscribe((token: string) => console.log(`Got a new token ${token}`));
  }





}
